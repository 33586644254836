<template>
    <div class="py-10 px-10 w-full bg-violet-800/25 text-white flex items-center justify-center flex-col">
        <p
            class="bg-gradient-to-r from-pink-500 to-violet-500 inline-block text-transparent bg-clip-text font-bold drop-shadow text-xl">
            A equipa da Focus deseja a todos um Natal repleto de alegria, paz e harmonia. Que o Ano Novo traga novas
            oportunidades e realizações, e que possamos continuar a fazer a diferença na vida daqueles que servimos.
        </p>
    </div>
</template>

<script>
    export default {
        name: 'UtilityBar',
    }
</script>

<style lang="">

</style>