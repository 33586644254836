<template>
    <div class="py-10 flex gap-10 flex-col">
        <router-link to="/">
            <button class="w-1/2 md:w-[20%] ">
                <img src="@/assets/logo_thick.svg" alt="">
            </button>
        </router-link>
        <div class="flex flex-col gap-10" v-if="message">
            <div class="w-[100px] m-auto">
                <img v-if='messageCode === 200' src="@/assets/check.svg" alt="">
                <img v-else src="@/assets/close.svg" alt="">
            </div>
            <p class='text-4xl text-center'
                :class="{ 'text-[#00ff00]': messageCode === 200, 'text-[#ff0000]': messageCode != 200 }">{{ message }}
            </p>
        </div>
    </div>
</template>

<script>

    import axios from 'axios'

    export default {
        name: "Cancel Component",
        data() {
            return {
                message: '',
                messageCode: 200,
            }
        },

        mounted() {
            this.fetchAppointments()
        },

        methods: {
            async fetchAppointments() {
                try {
                    const response = await axios.delete(`${process.env.VUE_APP_API_URL}/api/appointments/${this.$route.query.appointmentId}/cancel`);
                    this.messageCode = response.status
                    this.message = response.data.message;
                } catch (error) {
                    console.error('Error:', error);
                    this.message = error.response.data.message;
                    this.messageCode = error.response.status
                }
            },
        },
    }
</script>

<style lang="">

</style>