<template>
  <div>
    <button class="w-10 m-2 block ml-auto cursor-pointer" @click="hideAppointment">
      <img class="w-full" src="@/assets/close.svg" alt="" />
    </button>

    <div class="w-full pb-5">
      <p class=" text-white pb-2">Seleccione o seu Barbeiro favorito!</p>
      <div class="flex items-center justify-center gap-4">
        <button @click="selectBarber('Beto Almeida')"
          class="bg-[#fae8dd] border border-[#ffffff] rounded p-2 w-[10rem] hover:bg-[#ffc8bb]"
          :class="{ 'selected-barber': selectedBarber === 'Beto Almeida' }">
          Beto Almeida
        </button>
        <button @click="selectBarber('Bruno Rocha')"
          class="bg-[#fae8dd] border border-[#ffffff] rounded p-2 w-[10rem] hover:bg-[#ffc8bb]"
          :class="{ 'selected-barber': selectedBarber === 'Bruno Rocha' }">
          Bruno Rocha
        </button>
      </div>
      <div v-if="!selectedBarber" class="no-barber-message">
        Por favor, escolha o barbeiro e o dia para verificar os horários disponíveis.
      </div>
    </div>

    <DatePicker ref="datePickerRef" is-expanded v-model="date" :disabled-dates="disabledDates" locale="pt-PT"
      color="red" :class="{ 'vc-container-disable': !selectedBarber }">
      <template #footer>
        <div class="w-full px-4 pb-3">
          <button class="bg-[#EE4445] hover:bg-[#EE613C] font-bold w-full px-3 py-1 rounded-md" @click="moveToday">
            Hoje
          </button>
        </div>
      </template>
    </DatePicker>

    <p v-if="closedFullDay" class="text-red-500 text-3xl py-10 font-extrabold uppercase">ESTAMOS ENCERRADOS NESTE DIA!
    </p>

    <div class="w-full flex flex-col justify-center py-4 items-center">

      <div class="grid grid-cols-3 gap-4 w-[80%] md:max-w-[50%]">
        <button v-for="time in timeSlots" :key="time" :disabled="!selectedBarber || isTimeDisabled(time)"
          class="rounded-md p-2 flex justify-center items-center text-lg font-semibold" :class="{
            'enabled-time-slot': !isTimeDisabled(time) && selectedBarber,
            'disabled-time-slot': isTimeDisabled(time) || !selectedBarber,
            'selected-time-slot': selectedTimeSlot === time,
          }" @click="selectTimeSlot(time)">
          {{ time }}
        </button>
      </div>
    </div>

    <div class="w-full flex items-center flex-col py-5 gap-y-4">
      <div class="md:max-w-[50%] w-[80%]">
        <input v-model="name" @click="handleInputClick" @blur="checkIfIsAValidName" @focus="inputErrorMessage.name = []"
          type="text" class="form-input" placeholder="O seu nome"
          :class="{ 'has-errors__input': inputErrorMessage.name.length > 0 }" />
        <p v-if="inputErrorMessage.name.length > 0" class="text-xs font-bold h-fit text-[#ff0000]">
          *{{ inputErrorMessage.name.join(" ") }}
        </p>
        <input v-model="email" type="text" class="form-input" placeholder="O seu email"
          @blur="checkIfIsAValidEmailAddress" @focus="inputErrorMessage.email = []"
          :class="{ 'has-errors__input': inputErrorMessage.email.length > 0 }" />
        <p v-if="inputErrorMessage.email.length > 0" class="text-xs font-bold h-fit text-[#ff0000]">
          *{{ inputErrorMessage.email.join(" ") }}
        </p>
        <input v-model="phone" type="tel" class="form-input rounded-md" placeholder="Número de Telemóvel" pattern="\d*"
          @blur="checkIfIsAValidPhoneNumber" @focus="inputErrorMessage.contact = []"
          :class="{ 'has-errors__input': inputErrorMessage.contact.length > 0 }" />
        <p v-if="inputErrorMessage.contact.length > 0" class="text-xs font-bold h-fit text-[#ff0000]">
          *{{ inputErrorMessage.contact.join(" ") }}
        </p>
        <textarea v-model="note" placeholder="Deixe uma nota ao seu Barbeiro"
          class="resize-none p-[10px] my-[10px] w-full form-input"></textarea>
      </div>
      <ButtonComfirm @click="sendAppointmentData()" :button-state="buttonState"
        :button-request-message="buttonErrorMessage" />
      <p class="text-white font-medium">
        *Todas as marcações podem estar sujeitas a alteração com o devido aviso.
      </p>
    </div>
  </div>
</template>

<script>
  import { DatePicker } from "v-calendar";
  import "v-calendar/style.css";
  import ButtonComfirm from "@/components/ButtonConfirmComp.vue";
  import axios from "axios";

  import {
    isAValidEmailAddress,
    isAValidPhoneNumber,
    isAValidName,
    hasMin2Chars,
  } from "@/helpers/validateInputs.js";

  // eslint-disable-next-line no-unused-vars
  import {
    invalidEmail,
    invalidName,
    invalidPhoneNumber,
  } from "@/helpers/errorMessages.js";

  export default {
    name: "AppointmentsPicker",
    components: {
      DatePicker,
      ButtonComfirm,
    },
    emits: ["hide-appointment"],
    data() {
      return {
        selectedTimeSlot: null,
        selectedBarber: null,
        appointmentDetails: [],
        timeSlots: [
          "9:30",
          "10:15",
          "11:00",
          "11:45",
          "12:30",
          "14:30",
          "15:15",
          "16:00",
          "16:45",
          "17:30",
          "18:15",
          "19:00",
        ],
        date: new Date(),
        name: "",
        email: "",
        phone: "",
        note: "",
        disabledTime: [],
        buttonState: "idle",
        buttonErrorMessage: "",
        selectedDate: null,
        inputErrorMessage: {
          name: [],
          contact: [],
          email: [],
        },
        disabledDates: [],
        exceptionalTimes: [],
        closedFullDay: false,
      };
    },

    created() {
      this.disableAllDates();
    },
    methods: {
      selectBarber(barberName) {
        if (this.selectedBarber === barberName) {
          this.selectedBarber = null;
        } else {
          this.selectedBarber = barberName;
        }

        this.selectedTimeSlot = null;
        this.buttonState = 'idle';
        this.fetchAppointments();
        this.getExceptionTimes();
      },
      setDisabledDates() {

        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        const disabledDates = [];

        for (let i = 0; i < 365; i++) {
          const date = new Date();
          const date_previous = new Date();
          date.setDate(currentDate.getDate() + i);
          date_previous.setDate(currentDate.getDate() - i);
          date.setHours(0, 0, 0, 0);

          if (date.getDay() === 0 || date.getDay() === 1) {
            disabledDates.push(date);
          }

          if (this.exceptionalTimes) {

            const isExceptionalTimeClosed = this.exceptionalTimes.some(
              (exceptionalTime) =>
                this.isSameDate(date, exceptionalTime.date) && exceptionalTime.startTime === 'Dia Completo' && exceptionalTime.status === "closed"
            );

            if (isExceptionalTimeClosed) {
              disabledDates.push(date);
            }

            const isExceptionalTimeOpen = this.exceptionalTimes.some(
              (exceptionalTime) =>
                this.isSameDate(date, exceptionalTime.date) &&
                exceptionalTime.status === "open"
            );

            if (isExceptionalTimeOpen) {
              const index = disabledDates.indexOf(date);
              if (index !== -1) {
                disabledDates.splice(index, 1); // Remove the date from disabledDates
              }
            }
          }

          if (date_previous < currentDate) {
            disabledDates.push(date_previous);
          }
        }

        this.disabledDates = disabledDates;
      },
      disableAllDates() {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        const disabledDates = [];

        for (let i = 0; i < 365; i++) {
          const date = new Date();
          const date_previous = new Date();
          date.setDate(currentDate.getDate() + i);
          date_previous.setDate(currentDate.getDate() - i);
          date.setHours(0, 0, 0, 0);
          disabledDates.push(date);
          disabledDates.push(date_previous);
        }
        this.disabledDates = disabledDates;

      },
      exceptionalTimesByDate(date, time) {
        if (!date) return false;

        const matchingExceptionalTime = this.exceptionalTimes.find(
          (exceptionalTime) => this.isSameDate(date, exceptionalTime.date)
        );

        if (!matchingExceptionalTime) return false;

        // Case 1: Exceptional start time is a specific time slot
        if (this.timeSlots.includes(matchingExceptionalTime.startTime)) {
          const startTimeIndex = this.timeSlots.indexOf(matchingExceptionalTime.startTime);
          const currentTimeIndex = this.timeSlots.indexOf(time);

          if (matchingExceptionalTime.status === "closed") {
            return currentTimeIndex >= startTimeIndex; // Disable up to the start time
          } else if (matchingExceptionalTime.status === "open") {
            return currentTimeIndex < startTimeIndex ? true : false; // Enable from the start time onward
          }
        }

        // Case 2: Exceptional start time is "Manhã", "Tarde", or "Dia Completo"
        const timeRanges = {
          "Manhã": ["9:30", "12:30"],
          "Tarde": ["14:30", "19:00"],
          "Dia Completo": ["9:30", "19:00"],
        };

        const range = timeRanges[matchingExceptionalTime.startTime];
        if (!range) return false; // Invalid exceptional start time



        const [start, end] = range;
        const startTimeIndex = this.timeSlots.indexOf(start);
        const endTimeIndex = this.timeSlots.indexOf(end);
        const currentTimeIndex = this.timeSlots.indexOf(time);

        if (currentTimeIndex >= startTimeIndex && currentTimeIndex <= endTimeIndex) {
          // Correctly handle "closed" and "open" statuses within the time range
          return matchingExceptionalTime.status === "closed"
            ? true // Disable time slots for "closed"
            : false; // Enable time slots for "open"
        }

        return matchingExceptionalTime.status === "open" ? true : false;
      },

      isSameDate(date1, date2) {
        const formattedDate = new Date(date2);
        return (
          date1.getFullYear() === formattedDate.getFullYear() &&
          date1.getMonth() === formattedDate.getMonth() &&
          date1.getDate() === formattedDate.getDate()
        );
      },
      isSundayOrMonday(date) {
        return date.getDay() === 0 || date.getDay() === 1;
      },
      isExceptionalDate(date) {
        return this.exceptionalTimes.some((exception) =>
          this.isSameDate(date, exception.date)
        );
      },
      isTimeSlotDisabled() {
        const selectedDate = new Date(this.date);
        const selectedDateTime = new Date(
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          selectedDate.getDate()
        );

        if (
          this.isSundayOrMonday(selectedDate) &&
          !this.isExceptionalDate(selectedDateTime)
        ) {
          return true;
        }

        return false;
      },
      hideAppointment() {
        this.$emit("hide-appointment");
        this.resetForm();
        this.buttonState = "idle";
        this.selectedBarber = null
      },
      selectTimeSlot(time) {
        if (time != null) this.selectedTimeSlot = time;
        if (this.buttonState == "error") this.buttonState = "idle";
      },
      isTimeDisabled(time) {
        return (
          this.exceptionalTimesByDate(this.date, time) ||
          this.disabledTime.includes(time) ||
          this.isTimeSlotInPast(time) ||
          this.isTimeSlotDisabled()
        );
      },
      isTimeSlotInPast(timeSlot) {
        const currentDate = new Date();
        const timeSlotDate = new Date(this.date);
        const timeSlotParts = timeSlot.split(":");
        timeSlotDate.setHours(
          parseInt(timeSlotParts[0]),
          parseInt(timeSlotParts[1])
        );

        return timeSlotDate < currentDate;
      },
      isCurrentTimeAfterLastTimeslot() {
        const currentTime = new Date();
        const lastTimeSlot = this.timeSlots[this.timeSlots.length - 1];
        const lastTimeSlotParts = lastTimeSlot.split(":");
        const lastTimeSlotHours = parseInt(lastTimeSlotParts[0]);
        const lastTimeSlotMinutes = parseInt(lastTimeSlotParts[1]);

        const lastTimeslotDate = new Date(
          this.date.getFullYear(),
          this.date.getMonth(),
          this.date.getDate(),
          lastTimeSlotHours,
          lastTimeSlotMinutes
        );

        return currentTime > lastTimeslotDate;
      },
      async getExceptionTimes() {
        try {
          const response = await axios.get(
            `${process.env.VUE_APP_API_URL}/api/exceptions?barberName=${this.selectedBarber}`
          );
          const exceptions = response.data;
          this.exceptionalTimes = exceptions;

          this.setDisabledDates();
        } catch (error) {
          console.error("Error fetching exceptions:", error);
        }
      },
      async fetchAppointments() {
        try {
          const date = new Date();
          const isoDateTime = new Date(
            this.date.getTime() - date.getTimezoneOffset() * 60000
          ).toISOString();
          const response = await axios.get(
            `${process.env.VUE_APP_API_URL}/api/filteredAppointments/${isoDateTime}?barber=${this.selectedBarber}`
          );
          this.appointmentDetails = response.data;
          this.refreshDisabledTime();
        } catch (error) {
          console.error("Error:", error);
        }
      },
      async sendAppointmentData() {
        if (
          Object.keys(this.inputErrorMessage).some(
            (key) => this.inputErrorMessage[key].length > 0
          )
        ) {
          this.buttonState = "error";
          this.buttonErrorMessage = "Por favor, corrija os erros no formulário.";
          return;
        }

        const errorMessage = this.getValidationErrorMessage();

        if (errorMessage) {
          this.buttonState = "error";
          this.buttonErrorMessage = errorMessage;
          return;
        }

        const TimeSlot = this.selectedTimeSlot;
        const TimeSlotParts = TimeSlot.split(":");
        const TimeSlotHours = parseInt(TimeSlotParts[0]);
        const TimeSlotMinutes = parseInt(TimeSlotParts[1]);

        const dateFormat = this.date;
        dateFormat.setHours(TimeSlotHours, TimeSlotMinutes, 0);
        const appointmentdate = new Date(
          dateFormat.getTime() - dateFormat.getTimezoneOffset() * 60000
        ).toISOString();

        let appointment = {
          date: appointmentdate,
          time: this.selectedTimeSlot,
          barber: this.selectedBarber,
          customerName: this.name,
          customerEmail: this.email,
          customerPhone: this.phone,
          note: this.note,
        };

        try {
          this.buttonState = "loading";
          await axios.post(
            `${process.env.VUE_APP_API_URL}/api/appointments/`,
            appointment
          );
          await this.fetchAppointments();
          this.buttonState = "success";

          setTimeout(() => {
            this.hideAppointment();
          }, 1500);
        } catch (error) {
          this.buttonState = "error";
          if (error.response.data.error) {
            this.buttonErrorMessage = this.parseErrorMessage(
              error.response.data.error
            );
          } else {
            this.buttonErrorMessage = "ERRO!";
          }
        }
      },
      refreshDisabledTime() {
        this.disabledTime = this.appointmentDetails
          .filter((item) => this.timeSlots.includes(item.time) && item.barber === this.selectedBarber)
          .map((appointment) => appointment.time);
      },
      resetForm() {
        this.selectedTimeSlot = null;
        this.name = "";
        this.email = "";
        this.phone = "";
        this.note = "";
        this.inputErrorMessage = {
          name: [],
          contact: [],
          email: [],
        };
      },
      moveToday() {
        this.date = new Date();
        this.$refs.datePickerRef.move(this.date);
      },
      getValidationErrorMessage() {
        if (!this.date) {
          return "Por favor, selecione uma data.";
        }
        if (!this.selectedTimeSlot) {
          if (this.isCurrentTimeAfterLastTimeslot()) {
            return "Nao há mais datas disponiveis para o dia selecionado!";
          }
          return "Por favor, selecione um horário disponível.";
        }
        if (!this.selectedBarber) {
          return "Escolha um barbeiro!";
        }
        if (!this.name) {
          return "Por favor, insira um nome.";
        }
        this.buttonState = "idle";
        return "";
      },
      parseErrorMessage(errorMessage) {
        return errorMessage;
      },
      handleInputClick() {
        if (this.buttonErrorMessage) {
          this.buttonState = "idle";
          this.buttonErrorMessage = "";
        }
      },
      checkIfIsAValidEmailAddress() {
        if (this.email) {
          this.email = this.email.trim();
          this.inputErrorMessage.email = [];
          if (isAValidEmailAddress(this.email)) return true;
          this.inputErrorMessage.email.push(invalidEmail);
          return false;
        }
      },
      checkIfIsAValidPhoneNumber() {
        if (this.phone) {
          this.inputErrorMessage.contact = [];
          if (isAValidPhoneNumber(this.phone)) return true;
          this.inputErrorMessage.contact.push(invalidPhoneNumber);
          return false;
        }
      },
      checkIfIsAValidName() {
        if (this.name && this.name !== "") {
          this.inputErrorMessage.name = [];
          if (isAValidName(this.name)) {
            if (hasMin2Chars(this.name)) {
              return true;
            } else {
              this.inputErrorMessage.name.push(invalidName);
              return false;
            }
          }
          this.inputErrorMessage.name.push(invalidName);
          return false;
        } else return;
      },
    },
    watch: {
      date(val) {
        if (val) this.fetchAppointments();
        this.resetForm();
        this.closedFullDay = false

        if (this.buttonErrorMessage) {
          this.buttonState = "idle";
          this.buttonErrorMessage = "";
        }
      },
    },
  };
</script>

<style lang="postcss">
  textarea {
    -webkit-border-radius: 0.365rem;
    -moz-border-radius: 0.365rem;
    border-radius: 0.365rem;
  }

  .no-barber-message {
    font-size: 22px;
    font-weight: 600;
    color: #ff0000;
    text-align: center;
    @apply pt-5
  }

  .time-slot {
    text-align: center;
    color: white;
  }

  .selected-time-slot {
    border-color: #ee613c !important;
    background-color: #ee4445 !important;
  }

  .enabled-time-slot {
    @apply bg-[#fae8dd] border border-[#ffffff] hover:bg-[#ffc8bb] text-black cursor-pointer;
  }

  .disabled-time-slot {
    background-color: #2b2b2b !important;
    opacity: 80%;
    cursor: not-allowed !important;
    pointer-events: none;
    border: 1px solid rgb(49, 49, 49) !important;
  }

  .selected-barber {
    border-color: #ee613c !important;
    background-color: #ee4445 !important;
  }

  .form-input {
    margin: 10px 0;
    padding: 10px;
    width: 100%;
    font-size: 16px;
    border: 1px solid #f1f1f1;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s;
  }

  .form-input:hover,
  .form-input:focus {
    border-color: #ee613c;
  }

  .vc-title span::first-letter {
    text-transform: uppercase;
  }

  .vc-focus:focus-within {
    box-shadow: none;
  }

  .vc-day-content:focus {
    outline: none;
  }

  .vc-container {
    @apply w-[80%] md:max-w-[50%];
  }

  .vc-container-disable {
    opacity: 15%;
    pointer-events: none;
  }

  .has-errors__input {
    border: 5px solid rgb(255, 0, 0) !important;
  }

  .vc-day-content:hover {
    background-color: transparent;
  }
</style>
